import React from 'react';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default ({ image, text, containerClass }) => (
  <Grid item container xs={12} sm={4} md={4} xl={4}
    className={containerClass}>
    <Grid item xs={12} sm={12} md={12} className={styles.imageContainer}>
      <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
        objectFit='contain'
        className={styles.image}
        fluid={image.fluid}
        alt={text}/>
      </Grid>
    <Grid item xs={12} sm={12} md={12}
      className={styles.thirdTextContainer}>
      <Typography variant='subtitle2' align='center' weight='bold'>
        {text}
      </Typography>
    </Grid>
  </Grid>
);