import React from "react";
import Layout from "../../../components/Layout";
import SEO from "../../../components/seo";
import Subeader from '../../../components/Header/navmap/dealership';
import Hero from './Hero';
import DigitalServices from './DigitalServices';
import DataDrivenVehicle from './DataDrivenVehicle';
import Motivation from './Motivation';
import CallToAction from '../../CallToAction';
import sitemap from '../../../data/sitemap';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.industries}
    subpage={sitemap.dealership._}
    subheader={Subeader}
    url='_url:dealership'>
    <SEO {...seo} location={location}/>
    <Hero/>
    <DigitalServices/>
    <DataDrivenVehicle/>
    <Motivation/>
    <CallToAction/>
  </Layout>
);