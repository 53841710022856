import React from "react";
import Content from '../views/Dealership/Main';

export default ({location}) => (
  <Content location={location} seo={{
    lang:'en',
    title: "A complete connected car solution for dealerships",
    description: 'Connected car solution for car dealers and customers; Enable the workshop department to increase customer retention and lifetime value.',
    meta: [{
      name: 'keywords',
      content: 'Connected Car for Dealerships'
    }]
  }}/>
);